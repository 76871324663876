









import { Vue, Component, Prop } from "vue-property-decorator";

import { Locations } from '@/store/modules';

@Component({
  name: 'Container'
})
export default class Home extends Vue {
  @Prop({ required: true }) type!: string;
  @Prop({ required: false }) amount?: number;

  get imageUrl() {
    return `/reusables/${this.type}.png`
  }

  get containerData() {
    return Locations.containerTypes.find(containerType => containerType.id === this.type) || { name: 'Unknown container', size: 'Unknown size' };
  }

  mounted() {
    if (!Locations.containerTypes) {
      Locations.fetchAll();
    }
  }
}
